<template>
	<div class="">
		<router-view />
	</div>
</template>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body, html {
	height: 100%;
	font-family: 'Roboto', sans-serif;
}

#app {
	height: 100%;
	& > div {
		height: 100%;
	}
}

.content-align {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.spinner-border {
    --bs-spinner-border-width: 0.65em;
}

</style>
