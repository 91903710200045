<template>
	<div class="pt-5 pb-5">

		<div class="loading" v-if="loading">
			<div class="text-center">
				<div class="spinner-border text-primary x2" style="width: 5rem; height: 5rem;" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<br>
				<small class="text-primary">Loading...</small>
			</div>
		</div>

		<!-- <pre class="debug">
			{{ products }}
		</pre> -->

		<!-- <pre class="debug right">
			{{ v.$error }}
			<hr>
			{{ v.$errors }}
		</pre> -->

		<nav>
			<img :src="require('@/assets/bk-logo-navbar.webp')" alt="">
		</nav>
		<div class="container pt-5">
			<h1 class="text-danger">Create a new link</h1>

			<div class="d-none">
				<ValidateEach v-for="(item, index) in products" :key="index" :state="item" :rules="rules">
					<template #default="{ v }">
						<div>
							<input v-model="v.id.$model" type="text">
							<div v-for="(error, errorIndex) in v.id.$errors" :key="errorIndex">
								{{ error.$message }}
							</div>
						</div>
					</template>
				</ValidateEach>
			</div>

			<hr />

			<button type="submit" class="btn btn-dark btn-lg mb-3" @click.prevent="addProduct()"> + Add new</button>

			<div class="row g-5" v-if="products.length > 0">
				<div class="col col-md-3" v-for="(product, key) in products" :key="key">

					<div class="card" :class="{ 'custom-card' : products.length >= 2 }">
						<button type="button" class="btn-close" aria-label="Close" @click="removeProduct(key)"></button>

						

						<div class="img-product">
							<img v-if="product.id.length <= 0" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt="">
							<img v-else class="card-img-top bd-placeholder-img p-3" :src="product.image_url">
						</div>
			
						<div class="card-body">
							
							<select @change="productChange($event, $event.target.selectedIndex, key)" v-model="product.id" class="form-select w-100 mb-3">
								<option selected value="">Select a Product...</option>
								<option v-for="(productSelect, key) in getProductsList" :key="key" :value="productSelect.id">{{ productSelect.name }}</option>
							</select>

							<select :disabled="variants[key].length === 0 || product.id.length <= 0 || variants[key].options === undefined || variants[key].options[0].values.length <= 0" v-model="product.options" class="form-select w-100">
								<option selected value="null">Select a Variant</option>
								<option v-for="(variant, keyVariant) in variants[key].options[0].values" :key="keyVariant" :value="{ [variants[key].options[0].id] : variant.id }"> {{ variant.name }} </option>
							</select>

						</div>
					</div>

				</div>
			</div>

			<hr>

			<div class="row g-3 align-items-center mt-1">
				<div class="col-auto">
					<label for="discount" class="h4 text-secondary">Apply the discount (if any):</label>
				</div>
				<div class="col">
					<input v-model="discount" type="text" class="form-control w-100" id="discount">
				</div>
			</div>

			<hr />

			<div class="row g-3 align-items-center mt-1">
				<div class="col-auto">
					<label class="h4 text-secondary">Way to open the page:</label>
				</div>
				<div class="col-auto">
					<div class="form-check form-check-inline">
						<div>
							<input class="form-check-input" type="radio" id="another-tab" v-model="targetLink" name="wayOpen" value="">
							<label class="form-check-label" for="another-tab">In another tab</label>
						</div>
					</div>
					<div class="form-check form-check-inline">
						<div>
							<input class="form-check-input" type="radio" id="same-tab" v-model="targetLink" name="wayOpen" value="this">
							<label class="form-check-label" for="same-tab">In the same tab</label>
						</div>
					</div>
				</div>
			</div>

			<hr />

			<!-- <button type="submit" class="btn btn-dark btn-lg my-3" @click.prevent="updateLink()">Update Link</button> -->

			<div class="mb-3">
				<label for="generatedLink" class="form-label text-muted h4">Generate link</label>
				<input type="text" class="form-control" id="generatedLink" :placeholder="generatedLink">
			</div>

			<div class="row justify-content-between mt-5">
				<div class="col-2">
					<button type="button" class="btn w-100 btn-danger btn-lg">Cancel</button>
				</div>
				<div class="col-2">
					<button type="submit" class="btn w-100 btn-dark btn-lg" :disabled="v.$error" @click.prevent="copyLink()">Copy Link</button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import axios from 'axios'

import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { ValidateEach } from '@vuelidate/components'
import { required } from '@vuelidate/validators'

let serialize = function (obj) {
	var str = [];
	for (var p in obj)
		// eslint-disable-next-line
		if (obj.hasOwnProperty(p)) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	return str.join("&");
}

// @ is an alias to /src
export default {
	name: 'CreateLinkView',
	setup() {
		const rules = {
			id: {
				required, $autoDirty: true
			},
		}
		const products = reactive([])
		const v = useVuelidate()

		return {
			rules,
			products,
			v
		}
	},
	components: { 
		ValidateEach
	},
	data() {
		return {
			supportsCB:false,
			loading: false,
			shakerModel: null,
			stickPackModel: null,
			target: false,
			// products: [],
			variants: [
			],
			timeLink: "001",
			discount: "",
			targetLink: "",
			// generatedLink: `https://modal.buckedup.com/?time=${this.timeLink}&discount=${this.discount}&target=${this.targetLink}`,
			getProductsList: [],
			getVariantsList: [],
			productsQuery: "",
			teste: ""
		}
	},
	watch: {
		products: {
			deep: true,
			handler(e) {
				let tempList = {};

				e.forEach( (product, index) => {
					console.log("generatedLink Key", product, index)
					tempList[`product${index}`] = product.id
					console.log(product.options)
					if(product.options != {}) {
						if(Object.keys(product.options)[0] != undefined ) tempList[`variant${index}ID`] = Object.keys(product.options)[0]
						if(product.options[Object.keys(product.options)[0]] != undefined ) tempList[`variant${index}`] = product.options[Object.keys(product.options)[0]]
					}
					
					if(tempList[`product${index}`] === null) return

					else this.productsQuery= serialize(tempList)

				});
			}
		}
	},
	methods: {
		addProduct() {
			let product = {
				id: "",
				quantity: 1,
				options: {},
			}

			// this.variants.splice(this.products.length - 1, 0, { options: [ { values: [] } ] } );
			this.variants.push({ options: [ { values: [] } ] } );

			if(this.products.length < 5) {
				this.products.push(product)
				this.v.$touch()
			}
		},
		productChange(event, selectedIndex, key) {
			const index = this.getProductsList.findIndex(object => {
				return object.id === event.target.value;
			});

			this.products[key].image_url = this.getProductsList[index].image_url
			this.products[key].options = {};
			this.variants[key] = {
				options: [{
					values: []
				}]
			}

			this.fetchVariantsList(event.target.value, key)
		},
		removeProduct(key){
			if(this.products.length > 1) {
				this.products.splice(key, 1);
				this.variants.splice(key, 1);
			}
		},
		updateLink(){},
		copyLink() {
			const el = document.createElement('textarea');
			el.value = this.generatedLink;
			el.setAttribute('readonly', '');
			el.style.position = 'absolute';
			el.style.left = '-9999px';
			document.body.appendChild(el);
			const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			if (selected) {
				document.getSelection().removeAllRanges();
				document.getSelection().addRange(selected);
			}
			this.teste = "lien copié dans le presse-papiers";
		},
		async fetchVariantsList(id, key) {
			this.loading = !this.loading
			try {
				let { data } = await axios.get(`https://ar5vgv5qw5.execute-api.us-east-1.amazonaws.com/list/${id}`)
				if(data.product.options.length > 0) {
					this.variants[key] = data.product;
				} else {
					this.variants[key] = { options: [{ values: [] }] }
				}

				this.loading = !this.loading
			} catch (error) {
				this.loading = !this.loading
				console.log(error)
			}
		},
		async fetchProductsList() {
			this.loading = !this.loading
			try {
				let { data } = await axios.get('https://ar5vgv5qw5.execute-api.us-east-1.amazonaws.com/list')
				this.getProductsList = data.products.sort((a, b) => {
					let x = a.name.toLowerCase();
					let y = b.name.toLowerCase();
					return x < y ? -1 : x > y ? 1 : 0;
				});
				this.loading = !this.loading
			} catch (error) {
				this.loading = !this.loading
				console.log(error)
			}
		}
	},
	computed: {
		generatedLink() {
			return `https://select.buckedup.com/?time=${this.timeLink}&discount=${this.discount}&open=${this.targetLink}&${this.productsQuery}`
		},
	},
	mounted() {
		this.fetchProductsList();
		this.addProduct()
	},
	created() {
		if(navigator.clipboard) {
			this.supportsCB = true;
		}
	},
}
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body, html {
	background-color: #f0f2f9;
	height: 100%;
	color: #545967;
	font-family: 'Roboto', sans-serif;
}

.img-product {
	height: 250px;
	width: 100%;
	position: relative;
	overflow: hidden;
	margin-top: 2rem;
	border-radius: 1rem;
	& > img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
.w-100{
	width: 100%;
}

#app {
	height: 100%;
}

nav {
	text-align: center;
	padding: 1rem 0;
	background-color: #191919;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 998;
	img {
		max-width: 10%;
	}
}
.content-align {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.card {
	.btn-close {
		position: absolute;
		top: 5px;
		right: 5px;
	}
	img {
		max-width: 100%;
		border-radius: 1.7rem;
	}
	&.custom-card {
		&:after {
			content: "+";
			position: absolute;
			display: block;
			color: red;
			right: -11%;
			font-size: 2rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.row {
	.col {
		&:last-child {
			.card {
				&.custom-card {
					&:after {
						display: none !important;
					}
				}
			}
		}
	}
}

h1 {
	margin-top: 1rem;
}
.loading {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
}
.debug {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 250px;
	background: #000;
	color: green;
	z-index: 999;
	&.right {
		left: auto;
		right: 0;
	}
}
</style>
