<template>
	<div class="h-100 w-100">
		<div class="container h-100">
			<div class="row h-100 justify-content-center align-content-center">
				<div class="col-6">
					<div class="card">
						<img :src="require('@/assets/bk-logo-navbar.webp')" alt="" @click="count++" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'HomeView',
  components: {
  },
  data(){
	return {
		count: 0
	}
  },
  watch:{
	count(e){
		if(e >= 6) {
			this.$router.push({name: 'createLink'})
		}
	}
  },
  methods:{
  },
  mounted(){
  }
}
</script>

<style lang="scss" scoped>
.h-100.w-100 {
	background: url('@/assets/bg-01.png') no-repeat center center / cover
}
.card {
	border: none;
	padding: 3rem;
}
</style>
